<script lang="ts" setup>
import { ref } from "vue";
import ModelError from "../components/ModelError.vue";
import ContactMail from "./ContactMail";
import http from "./http";
import logger from "../logger";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const error = ref("");
const isBusy = ref(false);
const status = ref("");
const mail = ref(new ContactMail());
const model = mail.value.getModel();

async function onSubmit() {
  logger.log(`Starting Submit`);

  // reset
  status.value = "";
  isBusy.value = false;
  error.value = "";

  // Validate
  if (model.value.$validate && (await model.value.$validate())) {
    // Save
    isBusy.value = true;
    logger.log(`Sending Mail...`, mail.value);
    if (await http.sendMail(mail)) {
      model.value.$reset();
      mail.value.reset();
      status.value = t("messagesent");
      logger.log(`Mail Sent`);
    } else {
      error.value = t("errorsent");
      logger.log(`Mail failed`);
    }
    isBusy.value = false;
  }
}

</script>

<template>
  <div>
    <div v-if="isBusy"
         class="w-full p-1 text-center text-gray-300 rounded mb-4">
      <svg class="h-5 animate-spin fill-gray-300 inline-block"
           xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 512 512">
        <path
              d="M222.7 32.1c5 16.9-4.6 34.8-21.5 39.8C121.8 95.6 64 169.1 64 256c0 106 86 192 192 192s192-86 192-192c0-86.9-57.8-160.4-137.1-184.1c-16.9-5-26.6-22.9-21.5-39.8s22.9-26.6 39.8-21.5C434.9 42.1 512 140 512 256c0 141.4-114.6 256-256 256S0 397.4 0 256C0 140 77.1 42.1 182.9 10.6c16.9-5 34.8 4.6 39.8 21.5z" />
      </svg>
      {{ $t("sending") }}
    </div>

    <form method="post"
          @submit.prevent="onSubmit()"
          id="contact-form"
          novalidate>
      <p class="mb-0"
         id="error-msg"></p>
      <div id="simple-msg"></div>
      <div class="grid lg:grid-cols-12 lg:gap-5">
        <div class="lg:col-span-6 mb-5">
          <input name="name"
                 v-model="model.name.$model"
                 type="text"
                 class="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]"
                 :placeholder="$t('name')"
                 :class="{ error: model.name.$error && model.name.$invalid }">
          <ModelError v-if="model.$error"
                      :model="model.name" />
        </div>

        <div class="lg:col-span-6 mb-5">
          <input name="email"
                 v-model="model.email.$model"
                 type="email"
                 class="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]"
                 :placeholder="$t('email')"
                 :class="{ error: model.$error && model.email.$invalid }">
          <ModelError v-if="model.$error"
                      :model="model.email" />
        </div><!--end col-->
      </div>

      <div class="grid grid-cols-1">
        <div class="mb-5">
          <input name="subject"
                 v-model="model.subject.$model"
                 class="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]"
                 :placeholder="$t('subject')"
                 :class="{ error: model.$error && model.subject.$invalid }">
          <ModelError v-if="model.$error"
                      :model="model.subject" />
        </div>

        <div class="mb-5">
          <textarea name="comments"
                    v-model="model.msg.$model"
                    class="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-28 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]"
                    :placeholder="`${$t('comments')}:`"
                    :class="{ error: model.$error && model.msg.$invalid }"></textarea>
          <ModelError v-if="model.$error"
                      :model="model.msg" />
        </div>
        <div class="flex justify-end">
          <button type="submit"
                  id="submit"
                  name="send"
                  :disabled="(model.$dirty && model.$invalid) || isBusy"
                  class="btn bg-amber-500 hover:bg-amber-600 disabled:bg-amber-200 disabled:text-gray-500 border-amber-500 hover:border-amber-600 text-white rounded-md h-11 justify-center flex items-center">{{ $t("sendmessage")}}</button>
        </div>
      </div>
      <div>
        <div v-if="status"
             class="text-sm py-2 text-primary">{{ status }}</div>
        <div v-if="error"
             class="text-sm py-2 text-warning">{{ error }}</div>
      </div>
    </form>
  </div>
</template>
a
<style scoped>
.error {

  border-color: rgba(255, 0, 0, .5) !important;
  /* border-width: 1px !important; */
}
</style>
